.btn__base {
  background-color: #ffdf06;

  font-size: 14px;
  font-weight: 900;
  color: #5a0c7b;
  text-decoration: none;
  outline: none;
  padding: 10px;
  margin: auto;
  display: block;

  &:disabled {
    background-color: darkgray;
  }
}

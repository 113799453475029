.login {
  .avatar {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #eee;
  }

  h1 {
    color: rgb(241, 238, 25);
  }

  button {
    width: 90%;
  }

  .error__message {
    text-align: center;
    color: red;
    font-size: 20px;
    margin-top: 20px;
  }

  .login__change__language {
    background-color: transparent;
    outline: none;
    color: #fff;
    width: 40px;
    margin-right: 20px;
    border: 1px solid #fff;
  }
}


label {
  width: 80%;
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin: auto;
  text-align: center;
  margin-top: 20px;
}

label * {
  align-self: center;
}

label select {
  margin-left: 10px;
  width: 52%;
}
.control {
    margin: 1rem 0;
    display: flex;
    align-items: stretch;
    flex-direction: column;

    input {
        display: block;
        flex: 3;
        font: inherit;
        padding: 0.35rem;
        border-radius: 6px;
        border: 1px solid #ccc;
        text-align: center;
        font-weight: 700;
        letter-spacing: 3px;
        margin: auto;
        width: 80%;

        &:focus {
            outline: none;
            border-color: #4f005f;
            background: #f6dbfc;
        }
    }


    &.invalid {
        input {
            border-color: red;
            background: #fbdada;
        }
    }


}



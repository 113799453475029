*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}
body {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: url("./assets/img/main-back.png");
  background-color: #3f0069;
  background-repeat: no-repeat;
  background-size: 100%;
  max-width: 500px;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  outline: none;
}

.selected-lang { 
  border: 5px solid yellow;
  border-radius: 50px;
}

.rtl {
  direction: rtl;
}

.ltr {
  direction: ltr;
}